
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import XLSX from "xlsx";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ElConfigProvider from "element-plus";
import ko from "element-plus/lib/locale/lang/ko";

export default defineComponent({
  name: "version-results",
  components: { VPagination, ElConfigProvider },
  data() {
    return {
      results: [{ store: { cmp: {}, brand: {} } }],
      allResult: [],
      dueDatePickerOptions: {
        disabledDate: this.disabledDueDate,
      },

      checkItems: [],
      searchItem: {},
      allSelected: "false",
      page: 1,
      ko,
      brand_nm: "",
      store_nm: "",
      startDate: "",
      endDate: "",
      check_ver_ty: "",
      check_ver_nm: "",
    };
  },

  created() {
    this.getListData();
    base
      .getBrandList()
      .then((res) => {
        console.log(res);
        this.brands = res.data;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
    base
      .getStoreList()
      .then((res) => {
        console.log(res);
        this.stores = res.data;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
  },
  methods: {
    fnView(id) {
      console.log(id);
      sessionStorage.setItem("id", id);
      this.$router.push({
        name: "check-results",
        params: { id: id },
      });
    },

    disabledDueDate(time) {
      return time.getTime() < this.startDate;
    },

    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData(data) {
      const param = {
        page: this.page,
        brand_nm: this.brand_nm,
        store_nm: this.store_nm,
        start_date: this.startDate,
        end_date: this.endDate,
        check_ver_nm: this.check_ver_nm,
        check_ver_ty: this.check_ver_ty,
      };

      base
        .getResultList(param)
        .then((res) => {
          console.log(res);
          this.results = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
      base
        .getResultList()
        .then((res) => {
          console.log(res);
          this.allResult = res.data;
        })
        .catch((err) => {
          console.log(err);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    excelDownFunc() {
      console.log("리절트", this.allResult);
      this.excelCheckItem();
      const ws = XLSX.utils.json_to_sheet(this.allResult);
      const wb = XLSX.utils.book_new();
      ws.A1.v = "번호";
      ws.B1.v = "매장명";
      ws.C1.v = "브랜드명";
      ws.D1.v = "점검기준명";
      ws.E1.v = "점검일";
      XLSX.utils.book_append_sheet(wb, ws, "QSC_VersionResultData");
      XLSX.writeFile(wb, "result.xlsx");
    },
    excelCheckItem() {
      console.log("가공");
      const newitem = this.allResult.map((e) => {
        e.schedule = e.store.brand.brand_nm;
        e.store = e.store.store_nm;
        e.check_ver = e.check_ver.check_ver_nm;
        return e;
      });
    },

    findData() {
      const checkSearch = {
        check_item_title: this.checkitemSearch,
      };
      base
        .getCheckItemList(checkSearch)
        .then((res) => {
          console.log(res);
          this.checkItems = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    selectAll() {
      this.itemIds = [];
      if (this.allSelected) {
        for (const item in this.checkItems) {
          this.itemIds.push(this.checkItems[item].id);
        }
      }
    },
  },
});
